.events__background {
  background-image: url(../../assets/Frabio-Background.jpg);
  background-repeat: none;
  background-size: cover;
  width: 100vw;
  max-width: 100%;
}
.events__title {
  padding: 1rem;
}
.events__image__contain {
  Ion-img {
    object-fit: contain;
  }
}
.events__finished {
  position: relative;
  &::before {
    content: url(../../assets/promotedBanner.png);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

a.fc-event {
  width: 20px !important;
  height: 20px !important;
  display: inline-block;
  border-radius: 50%;
  font-size: 0px;
}

.fc-daygrid-day-events {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fc-daygrid-day-frame {
  height: 100%;
  :hover {
    cursor: pointer;
  }
}

.card__modal {
  overflow-x: none;
  .card {
    width: 500;
    background-color: #fff;
  }

  .card__header {
    height: 280px;
    width: 100%;
    background-size: cover;
  }

  .card__header__tag {
    text-transform: uppercase;
    margin: 0;
    padding: 15px;
  }

  .card__body {
    white-space: normal;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .card__startDate {
    font-size: 14px;
    color: grey;
    font-weight: 600;
  }

  .card__button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    font-family: inherit;
    color: #2080bb;
    transition: 0.25s;
    font-size: 16px;

    &:hover {
      transform: translate(10px, 0);
      cursor: pointer;
    }
  }
}

.events__container {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin: 0;
  height: 100%;
  gap: 1rem;

  .card {
    width: 280px;
    transition: 0.25s;
    margin: 1rem 0;
    background-color: #fff;
    border-radius: 6px;
  }

  .card__header {
    display: block;
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  .card__header__tag {
    text-transform: uppercase;
    margin: 0;
    padding: 0.5rem;
    color: white;
  }

  .card__body {
    white-space: normal;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .card__title {
    padding: 1rem 0;
  }
  .card__startDate {
    font-size: 14px;
    color: grey;
    font-weight: 600;
  }

  .card__button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    font-family: inherit;
    color: #2080bb;
    transition: 0.25s;
    font-size: 16px;

    &:hover {
      transform: translate(10px, 0);
      cursor: pointer;
    }
  }
}

.events__tabLabel {
  text-transform: capitalize !important;
  color: black !important;
  font-weight: 6400 !important;
  font-size: 1rem !important;
}
.event__tooltip {
  display: flex;
  & > * {
      position: relative;
      &::after {
          position: absolute;
          top: -30px;
          left: 0;
          width: auto;
          height: auto;
          white-space: nowrap;
          padding: 5px;
          border-radius: 5px;
          border: solid 1px black;
          background-color: #F4F5F8;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.2s, visibility 0.2s;
          z-index: 10;
      }
      &:hover::after {
          opacity: 1;
          visibility: visible;
      }
  }
  &--virtual::after {
      content: "Cet événement est en ligne";
  }
  &--presentiel::after {
      content: "Cet événement est en présentiel";
  }
  &--dollar::after {
      content: "Cet événement nécessite un paiement";
  }
  &--free::after {
      content: "Cet événement est gratuit";
  }
} 