// Shared
@import "./parts/variables";
@import "./parts/nav";
@import "./parts/general";
@import "./parts/company";
@import "./parts/form";
@import "./parts/avatar";
@import "./parts/noAdress";
@import "./parts/vivreSignUp";
@import "./parts/map";
@import "./parts/companyList";
@import "./parts/checkout";
@import "./parts/vivreMarker";
@import "./parts/carousel";
@import "./parts/footer";
@import "./parts/events";
@import "./parts/eventList";
@import "./parts/calendar";
@import "./parts/eventsCalendar";

body {
  margin: 0;
}
