.clickable__tag {
  cursor: pointer;
  margin: 0 1rem;
  padding: 2px 0; 
  position: relative;
  overflow: hidden;
  line-height: 1.5;
  p {
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: 0;
      width: 0;
      bottom: -2px;
      background: $blue;
      height: 2px;
      // transition-property: width;
      // transition-duration: 0.3s;
      // transition-timing-function: ease-in-out;
      // &:hover::after {
      //   left: 0;
      //   right: auto;
      //   width: 100%;
      // }
    }

  }
}
.clickable__active {
  border-bottom: 2px solid $blue;
}

// React router Link unset
a,
a:hover,
a:active {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer !important;
}

body {
  font-family: $Bebas;
}

// Border & box-shadow
.frabio__borderboxshadow {
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px !important;
  border-radius: 6px !important;
}

// Mui Button
.MuiButton-root {
  font-family: $Bebas !important;
}

.loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 200px;
    }
}
.forceDownload {
    background-color: #CECCDD;
    color: white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .forceDownload__logo {
        max-width: 100%;
    }
    .forceDownload__ios, .forceDownload__android {
        max-width: 50%;
    }
}

html {
  font-size: 16px;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.83rem;
}
h3 {
  font-size: 1.67rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.33rem;
}
h6 {
  font-size: 1.17rem;
}
p {
  font-size: 1rem;
}
a {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, p, a {
  margin: 0;
}