.footer__container {
  background-color: #97b8b6;
  // padding: 40px;
  color: white;
  position: relative;
  font-family: $Montserrat;
}
.footer__1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  img {
    max-width: 60%;
  }
}
.footer__2,
.footer__3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  span {
    display: block;
    margin-top: 10px;
    font-weight: normal;
    &:hover {
      color: $orange;
    }
  }
  a {
    span {
      &:hover {
        color: $orange;
      }
    }
  }
}
.footer__4 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  img {
    max-width: 60%;
  }
}
.footer__content--container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__seperator {
  height: 80%;
  width: 3px;
  background-color: #f75e11;
}
@media only screen and (max-width: 899px) {
  .footer__seperator {
    display: none;
  }
  .footer1,
  .footer__4 {
    justify-content: center;
  }
  .footer__container {
    padding: 4rem 0 0 0;
    .footer__sectionContainer {
    gap: 2rem;
    .footer__section {
        display: flex;
        justify-content: center;
      }
      .footer__1 {
        height: unset;
        width: 60%;
        margin-bottom: 2rem;
      }
      .footer__4 {
        img {
          max-width: 40%;
        }
      }
    }
  }
}
