.company {
  background-image: url(../../assets/Frabio-Background.jpg);
  background-position: bottom;
  background-repeat: none;
  background-size: cover;
  background-color: #f3f3f3;
  padding: 2rem 0;
}
.company__container {
  max-width: 700px;
  width: fit-content;
  margin: auto;
  padding: 20px;
  // border: solid grey 1px;
  // box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
  background-color: white;
  border-radius: 6px;
  .company__p {
    margin: 6px;
  }

  .company__title {
    text-align: center;
    margin-bottom: 2rem;
  }

  .company__span {
    font-weight: bold;
  }
  .company__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .company__logo {
    max-width: 500px;
  }
  .company__info {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    max-width: 100%;
  }
  .company__longDesc {
    div {
      overflow-wrap: break-word;
    }
  }
  .company__media {
    text-align: center;
    margin: 2rem 0;
  }
  .company__media__icon {
    display: flex;
    gap: 1rem;
  }
}
