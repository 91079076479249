.nav {
  display: block;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: $Montserrat;
  .nav__main {
    background-color: white;
    height: 75px;
    padding: 0 2rem 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px !important;
    .nav__nav {
      display: flex;
      align-items: center;
      span {
        margin: 0px 25px;
      }
    }
  }
  .nav__logo {
    height: 80%;
    display: flex;
    align-items: center;
    img {
      // height: 100%;
      width: 85px;
    }
  }
  .nav__cta {
    font-family: $Bebas;
    text-transform: unset;
    font-weight: 600;
    padding: 0.5rem 1rem;
    background-color: $orange;
    margin-left: 0.5rem;
    color: white;
    border: 1px solid $orange;
    &:hover {
      background-color: $blue;
    }
  }
  .nav__secondaryCta {
    font-family: $Bebas;
    text-transform: unset;
    font-weight: 600;
    padding: 0.5rem 1rem;
    color: $orange;
    border-color: $orange;
    // background-color: $orange;
    margin-left: 0.5rem;
    &:hover {
      color: $blue;
      border-color: $blue;
    }
  }
  @media only screen and (max-width: 1070px) {
    .nav__nav {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
    .nav__menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 15px 20px;
      position: absolute;
      top: 75px;
      right: 100%;
      opacity: 1;
      // transition: all 0.5s ease;
      box-shadow: rgb(0 0 0 / 12%) 6px 16px 22px !important;
      clip-path: inset(-100px 0 -100px -100px);
    }
    .nav__menu.active {
      background-color: white;
      opacity: 1;
      // transition: all 0.5s, ease-out;
      z-index: 99;
      width: unset;
      right: 0;
    }
    .hbg-menu {
      display: block;
      color: black;
      cursor: pointer;
      svg {
        font-size: 1.5rem;
      }
    }
    button {
      width: 100%;
    }
  }
}
