a.fc-event {
    width: 5px !important;
    height: 5px !important;
    display: inline-block;
    border-radius: 50%;
    font-size: 0px;
  }
  
  .fc-daygrid-day-events {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .fc-daygrid-day {
    min-height: 100px;
  }
  
  .fc-daygrid-day-frame {
    height: 100%;
  }
  
  .fc-toolbar-title {
    font-family: var(--ion-font-family, inherit);
    text-align: center;
    text-transform: capitalize;
  }
  
  .fc-toolbar {
    flex-direction: column;
  }
  .fc-toolbar-chunk {
    margin-top: 0.5rem;
    width: 100%;
  }
  
  .fc-button-group {
    width: 100%;
    justify-content: space-between;
    button {
      flex-grow: 0 !important;
    }
  }
  
  .fc-header-toolbar {
    justify-content: space-around;
  }
  
  .fc-view-harness {
    width: calc(100% - 24px);
    margin: 0 12px;
  }
  
  .fc-scroller {
    overflow: hidden !important;
  }