.form {
  background-image: url(../../assets/Frabio-Background.jpg);
  background-position: bottom;
  background-repeat: none;
  background-size: cover;
  background-color: #f3f3f3;
  padding: 2rem;
  height: 100%;
  min-height: calc(100vh - 100px - 4rem);
}
.form__container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 20px;
  // border: solid grey 1px;
  // box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
  background-color: white;
  border-radius: 6px;
  .form__step {
    padding-left: 1rem;
  }
  .MuiTextField-root {
    padding-bottom: 1.5rem;
  }
  .vivre__text {
    text-align: center;
  }
  .vivre__title {
    padding-top: 10px;
    color: #8a8a8a;
    font-weight: lighter;
  }
  .form__button {
    &--back {
      color: black !important;
      border: none !important;
      background-color: #f3f3f3;
      border-radius: 6px !important;
    }
    &--next {
      background-color: $orange !important;
      color: white;
      border-radius: 6px !important;
      box-shadow: none;
      &:hover {
        background-color: white !important;
        color: $orange !important;
        box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px !important;
      }
    }
  }
  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.54);
  }
}
