// Colors
$grey: #92949c;
$light-grey: #ffffff;
$blue: #2380b9;
$orange: #ef6823;

// Tags
// $artsetculture: #7b4949;
// $artsvisuel: #225a43;
// $chronique: #c54ec4;
// $cinemaettele: #5c4ec5;
// $danse: #1c0f7c;
// $divertissement: #deec0b;
// $environnement: #3ac718;
// $francophonie: #73996a;
// $federal: #996a6e;
// $gastronomie: #966a99;
// $histoire: #70996a;
// $jeunesse: #08cee8;
// $justice: #3caab8;
// $litterature: #acb83c;
// $loisirs: #bfd30b;
// $musique: #89540e;
// $opinions: #cd8018;
// $politique: #593f1c;
// $portrait: #1151eb;
// $provincial: #2c1c1c;
// $publireportage: #491f1f;
// $sante: #70e0a9;
// $sciences: #dd0707;
// $sport: #55813b;
// $theatre: #d8b882;
// $viecommunautaire: #87dcfc;
// $economie: #6a9499;
// $education: #1d7c89;

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap");
$Montserrat: "Montserrat", sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
$Bebas: "Bebas Neue", sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,700;1,400&display=swap');
$Nunito: 'Nunito Sans', sans-serif;

