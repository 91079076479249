@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.carousel {
    height: 275px;
    overflow: hidden;
    cursor: pointer;
    image {
        object-fit: cover;
        // height: 100%;
    }
    .slick-slider, .slick-list, .slick-track {
        height: 100%;
    }
    .slick-slide {
        div:first-child {
            height: 100%;
        }
    }
}

.carousel__item {
    // height: 100%;
    a {
        // height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}