$opacity: 0.5;
$opacityTransition: 0.5s;
.avatar__container {
  text-align: center;
  margin: auto;
  position: relative;
  border: 1px solid transparent;
  overflow: hidden;

  .avatar__img {
    cursor: pointer;
    height: 250px;
    width: 250px;
    margin: auto;

    &:hover {
      opacity: $opacity;
      transition: $opacityTransition;
    }
  }

  .avatar__input {
    display: none;
  }

  .avatar__upload {
    text-transform: uppercase;
    color: var(--primary);
    margin-bottom: 25px;
    font-size: 0.7rem;
    font-weight: bold;
  }

  .avatar__contain {
    img {
      object-fit: contain;
    }
  }
  .avatar__cover {
    img {
      object-fit: cover;
    }
  }
  .form__button {
    &--back {
      color: black !important;
      border: none !important;
      background-color: #f3f3f3;
      border-radius: 6px !important;
    }
    &--next {
      background-color: $orange;
      color: white;
      border-radius: 6px !important;
      box-shadow: none;
      &:hover {
        background-color: white;
        color: $orange;
        box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px !important;
      }
    }
  }
}

.avatar__grid {
  display: flex;
  margin: 0;
  padding: 0;

  &__item {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      height: auto;
      width: 70px;
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.3s ease;
      background-color: #8a8a8a;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        color: white;
      }
    }

    &:hover .overlay {
      opacity: 1;
    }
  }
}
