.companyList {
  max-height: 89vh;
  overflow: auto;
  width: 30vw;
}
.companyList__container {
  margin: 0 2rem !important;
}
.companyList__stack {
  padding: 0 2rem;
  margin-top: 2rem !important;
  margin-bottom: 0.1rem !important;
  .MuiInputBase-root {
    border-radius: 6px !important;
    // box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px !important;
    background-color: #f2f2f2;
    border: none !important;
    .MuiSelect-select {
      padding: 16.5px 14px;
    }
  }
  .MuiOutlinedInput-notchedOutline,
  .MuiFormControl-root .Mui-focused {
    border-width: 0px !important;
  }
}
.companyList__list {
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
}
.companyList__promoted {
  background-image: url(../../assets/promotedBanner.png);
  background-repeat: no-repeat;
}
.companyList__list__logo {
  max-height: 100px;
  width: 100px;
  object-fit: contain;
  padding: 0 1rem;
}
.companyList__list__info {
  align-self: center;
}
.companyList__list__name {
  align-self: center;
  margin: 0.2rem 0;
}
.companyList__list__address,
.companyList__list__category {
  margin: 0;
  color: $grey;
  // font-size: 0.95rem;
}
