.noAdress__container {
  background-image: url(../../assets/Frabio-Background.jpg);
  background-position: bottom;
  background-repeat: none;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin: 2rem;
  font-family: $Bebas;
  .noAdress__company__div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: solid grey 1px;
    margin: 10px;
    padding: 10px;
    // background-color: var(--second-card);
    background-color: $light-grey;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
    border-radius: 6px;
    width: 300px;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
  }
}
.noAdress__title {
  text-align: center;
  font-family: $Bebas;
}
.noAdress__background {
  background-image: url(../../assets/Frabio-Background.jpg);
  background-position: bottom;
  background-repeat: none;
  background-size: cover;
  height: calc(100vh - 100px);
  width: 70vw;
}

